<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ReportsHeader from "@/components/reports-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ReportsHeader,
  },
  data() {
    return {
      limit: 20,
      page: 1,
      emps: [],
      transactions: [],
      accountingPeriods: [],
      selectedEmp: "",
      emp: {},
      accountFilter: {
        accounting_period_id: null,
        from: "",
        to: "",
      },
      search: "",
      totalCredit: 0,
      totalDebit: 0,
      index: null,
      selectedId: null,
    };
  },
  methods: {
    getEmps() {
      this.http.post("emps/search", { search: this.search }).then((res) => {
        if (res.status) {
          this.emps = res.data.map((emp) => {
            return `${emp?.id}-${emp?.name}`;
          });
        }
      });
    },
    getReport() {
      this.http
        .post("emps/report", {
          id: this.selectedId,
          accountFilter: this.accountFilter,
        })
        .then((res) => {
          if (res.status) {
            this.transactions = res.data;
            this.totalCredit = 0;
            this.totalDebit = 0;
            this.transactions.forEach((transaction) => {
              if (transaction.type == "credit") {
                this.totalCredit += parseFloat(transaction?.value);
              } else {
                this.totalDebit += parseFloat(transaction?.value);
              }
            });
          }
        });
    },
    selectEmp() {
      let empIndex = this.emps.indexOf(this.selectedEmp);
      this.index =
        "EMP-" +
        (empIndex < 10
          ? "000" + empIndex
          : empIndex < 100
          ? "00" + empIndex
          : empIndex < 1000
          ? "0" + empIndex
          : empIndex);
      console.log(this.emp);
      this.selectedId = this.selectedEmp.split("-")[0];
    },
    printReport() {
      window.print();
    },
    getAccountingPeriods() {
      this.http.get("accounting-peroids").then((resp) => {
        if (resp) {
          this.accountingPeriods = resp.data;
        }
      });
    },
  },
  computed: {
    filteredEmps() {
      return this.emps.filter((emps) => {
        return emps;
      });
    },
  },
  created() {
    this.getAccountingPeriods();
  },
};
</script>

<template>
  <Layout class="no-print">
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('emps.report')"
    />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="getReport()">
              <div class="p-3" style="background-color: rgb(246, 247, 248)">
                <div class="row">
                  <div class="col-auto d-flex" style="align-items: flex-end">
                    <div>
                      <label for="" class="d-block">{{
                        $t("emps.select")
                      }}</label>
                      <AutoComplete
                        @keyup="getEmps()"
                        v-model="selectedEmp"
                        :suggestions="filteredEmps"
                        @complete="search = $event.query"
                        style="color: #333 !important"
                      />
                    </div>
                    <div>
                      <label for="" class="d-block"></label>
                      <button
                        type="button"
                        style="margin-inline-start: 4px; height: 40px"
                        @click="selectEmp"
                        class="btn btn-primary"
                      >
                        {{ $t("popups.select") }}
                      </button>
                    </div>
                  </div>
                  <!-- <div class="col-3">
                
              </div> -->
                  <div class="col d-flex" style="height: 40px">
                    <div class="d-inline">
                      <label for="" class="d-block">{{
                        $t("popups.period")
                      }}</label>

                      <select
                        v-model="accountFilter.accounting_period_id"
                        class="form-select"
                        :disabled="selectedId == null"
                      >
                        <option
                          :value="period.id"
                          v-for="period in accountingPeriods"
                          :key="period"
                        >
                          {{
                            period?.start_date +
                            ` ${this.$i18n.locale == "ar" ? "الى" : "To"} ` +
                            period?.end_date
                          }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="d-inline"
                      style="margin-inline-start: 8px; margin-inline-end: 8px"
                    >
                      <label for="" class="d-block">{{
                        $t("popups.from")
                      }}</label>

                      <input
                        v-model="accountFilter.from"
                        :disabled="selectedId == null"
                        type="date"
                        class="form-control"
                      />
                    </div>
                    <div class="d-inline">
                      <label for="" class="d-block">{{
                        $t("popups.to")
                      }}</label>

                      <input
                        v-model="accountFilter.to"
                        :disabled="selectedId == null"
                        type="date"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-auto d-flex" style="align-items: center">
                    <div></div>
                    <button
                      :disabled="selectedId == null"
                      class="btn btn-success"
                    >
                      {{ $t("popups.generate_report") }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-3">
        <AutoComplete
          @keyup="getEmps()"
          v-model="selectedEmp"
          :suggestions="filteredEmps"
          @complete="search = $event.query"
          style="color: #333 !important; border-radius: 10px !important"
        />
      </div>
      <div class="col-auto">
        <button @click="geEmpReport()" class="btn btn-primary">
          {{ $t("popups.search") }}
        </button>
      </div>
      <div class="col" v-if="transactions.length > 0">
        <br />
        <button class="btn btn-success float-end" @click="printReport()">
          <i class="bx bx-printer"></i>
        </button>
      </div>
    </div> -->

    <div class="row mt-4" v-if="transactions.length > 0">
      <div class="card card-body">
        <ReportsHeader
          :reportName="$t('reports.emps_statement')"
          :accountNo="index + 1"
          :accountName="transactions[0]?.name"
        />

        <div class="table-responsive">
          <table
            class="table table-centered table-nowrap table-striped table-hover align-middle"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
          >
            <thead>
              <tr
                class="text-light"
                style="background-color: #2a3042 !important"
              >
                <!-- <th scope="col">{{ $t("general_ledger.account_id") }}</th> -->
                <th scope="col">{{ $t("customers_report.date") }}</th>
                <!-- <th scope="col">{{ $t("customers_report.particular") }}</th> -->
                <th scope="col">{{ $t("customers_report.descr") }}</th>
                <th scope="col">{{ $t("customers_report.debit") }}</th>
                <th scope="col">{{ $t("customers_report.credit") }}</th>
                <th scope="col">{{ $t("customers_report.balance") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class=""
                v-for="transaction in transactions"
                :key="transaction"
              >
                <!-- <td>{{ transaction.code }}</td> -->
                <!-- <td>{{ transaction.name }}</td> -->
                <td>{{ transaction?.created.split("T")[0] }}</td>
                <td>
                  {{
                    $i18n.locale == "ar"
                      ? transaction?.descr
                      : transaction?.descr_en
                  }}
                </td>
                <td>
                  {{
                    transaction?.type == "debit"
                      ? parseFloat(transaction?.value).toLocaleString()
                      : ""
                  }}
                </td>
                <td>
                  {{
                    transaction?.type == "credit"
                      ? parseFloat(transaction?.value).toLocaleString()
                      : ""
                  }}
                </td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td class="total-style">
                  {{ parseFloat(totalDebit).toLocaleString() }}
                </td>
                <td class="total-style">
                  {{ parseFloat(totalCredit).toLocaleString() }}
                </td>
                <td class="total-style">
                  {{
                    (
                      parseFloat(totalCredit) - parseFloat(totalDebit)
                    ).toLocaleString()
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <th>{{ $t("reports.report_date") }} :</th>
              <th>
                {{
                  `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getDay()},
                 ${new Date().getHours()}:${new Date().getMinutes()}`
                }}
              </th>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </Layout>
  <div class="row mt-4 print" v-if="transactions.length > 0">
    <div class="card card-body">
      <ReportsHeader
        :reportName="$t('reports.emps_statement')"
        :accountNo="index + 1"
        :accountName="transactions[0]?.name"
      />

      <div class="table-responsive">
        <table
          class="table table-centered table-nowrap table-striped table-hover align-middle"
          :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        >
          <thead>
            <tr class="text-light" style="background-color: #2a3042 !important">
              <!-- <th scope="col">{{ $t("general_ledger.account_id") }}</th> -->
              <th scope="col">{{ $t("customers_report.date") }}</th>
              <!-- <th scope="col">{{ $t("customers_report.particular") }}</th> -->
              <th scope="col">{{ $t("customers_report.descr") }}</th>
              <th scope="col">{{ $t("customers_report.debit") }}</th>
              <th scope="col">{{ $t("customers_report.credit") }}</th>
              <th scope="col">{{ $t("customers_report.balance") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="" v-for="transaction in transactions" :key="transaction">
              <!-- <td>{{ transaction.code }}</td> -->
              <!-- <td>{{ transaction.name }}</td> -->
              <td>{{ transaction?.created.split("T")[0] }}</td>
              <td>
                {{
                  $i18n.locale == "ar"
                    ? transaction?.descr
                    : transaction?.descr_en
                }}
              </td>
              <td>
                {{
                  transaction?.type == "debit"
                    ? parseFloat(transaction?.value).toLocaleString()
                    : ""
                }}
              </td>
              <td>
                {{
                  transaction?.type == "credit"
                    ? parseFloat(transaction?.value).toLocaleString()
                    : ""
                }}
              </td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td class="total-style">
                {{ parseFloat(totalDebit).toLocaleString() }}
              </td>
              <td class="total-style">
                {{ parseFloat(totalCredit).toLocaleString() }}
              </td>
              <td class="total-style">
                {{
                  (
                    parseFloat(totalCredit) - parseFloat(totalDebit)
                  ).toLocaleString()
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <th>{{ $t("reports.report_date") }} :</th>
            <th>
              {{
                `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getDay()},
                 ${new Date().getHours()}:${new Date().getMinutes()}`
              }}
            </th>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
/*
.table,
td {
  border: 1px solid black;
}
*/
.print {
  display: none;
}
@media print {
  .no-print {
    display: none;
  }
  .print {
    display: block;
  }
}
</style>
